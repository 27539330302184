import React from 'react'
import { graphql } from 'gatsby'
import BlogPost from '../../components/TemplateParts/BlogPost'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import pageThemeShortheader from '../../gatsby-plugin-chakra-ui/theme-shortheader'
import SEO from '../../components/SEO'

const theme = extendTheme({ ...pageThemeShortheader })

const Post = ({ data, pageContext }) =>  {
  return (
    <ChakraProvider theme={theme}>
      <SEO
        title={!!data.page?.newExcerpt?.seoTitle ? data.page.newExcerpt.seoTitle : data.page.title}
        description={data.page.newExcerpt.newExcerpt}
        image={!!data.page.featuredImage?.node?.localFile?.publicURL ? data.page.featuredImage.node.localFile.publicURL : null}
        imagew={!!data.page.featuredImage?.node?.mediaDetails?.width ? data.page.featuredImage.node.mediaDetails.width : null}
        imageh={!!data.page.featuredImage?.node?.mediaDetails?.height ? data.page.featuredImage.node.mediaDetails.height : null}
      />
      <BlogPost data={data} pageContext={pageContext} />
    </ChakraProvider>
  )
}

export const query = graphql`
  query post($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPost(id: { eq: $id }) {
      title
      content
      newExcerpt {
        seoTitle
        hidePublishDate
        newExcerpt
      }
      author {
        node {
          name
        }
      }
      date(formatString: "MMMM Do, YYYY")
      blocksJSON
      heroContent {
        heroSlides {
          heroSlideContentH1
          heroSlideContentH4
          heroSlideLink {
            url
            title
            target
          }
          heroSlideIllustrationSelect
          heroSlideEffect
          secondaryButton {
            target
            title
            url
          }
          tertiaryButton {
            target
            title
            url
          }
          heroSlideImage {
            altText
            localFile {
              url
              ext
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 85, placeholder: NONE)
              }
            }
          }
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            ext
            publicURL
            ...HeroImage
          }
          mediaDetails {
            width
            height
          }
        }
      }
    }
    
    wp {
      mtaGeneralSettings {
        blogHeroContent {
          heroSlides {
            heroSlideContentH1
            heroSlideContentH4
            heroSlideLink {
              url
              title
              target
            }
            heroSlideIllustrationSelect
            heroSlideImage {
              altText
              localFile {
                url
                ext
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 85, placeholder: NONE)
                }
              }
            }
          }
        }
      }
    }

    nextPage: wpPost(id: { eq: $nextPage }) {
      title
      uri
    }

    previousPage: wpPost(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`

export default Post