/* eslint-disable */
import React from "react"
import { Link } from "gatsby"
import { Box, Heading, SimpleGrid } from '@chakra-ui/react'
import Layout from "../../components/Layout"
import { normalizePath } from "../../utils/get-url-path"
import Blocks from '../Blocks/RecursiveBlockRenderer'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const isJsonString = ( str ) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const BlogPost = ({ data, pageContext }) => {
  const { nextPage, previousPage, page, wp } = data
  const { title, featuredImage, author, date, newExcerpt } = page
  const { mtaGeneralSettings } = wp

  // Breadcrumbs
  const {
    breadcrumb: { crumbs },
  } = pageContext
  const crumbsUnslugged = crumbs.map((crumb) => { // Unsluggify breadcrumbs
    return {
      pathname: crumb.pathname,
      crumbLabel: crumb.crumbLabel.replace(/\-/g, ' ')
      .replace(/\w\S*/g, (txt) => { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() })
      .replace(/Mta/g, (txt) => { return txt.toUpperCase() })
    }
  })

  crumbsUnslugged[crumbsUnslugged.length - 1].crumbLabel = data.page.title
  
  // Hero
  let heroContent = null
  if (
    page.heroContent?.heroSlides[0]?.heroSlideContentH1 != null || 
    page.heroContent?.heroSlides[0]?.heroSlideIllustrationSelect !== 'none' ||
    page.heroContent?.heroSlides[0]?.heroSlideImage != null) {
    heroContent = page.heroContent
  } else {
    heroContent = mtaGeneralSettings.blogHeroContent
  }

  // Prepare fake page object to tell header to use shortheader
  const pageObj = {
    template: {
      templateName: 'Short Header'
    }
  }

  return (
    <Layout heroContent={heroContent} page={pageObj}>

      <Box className="container" mb="200px" mt="200px" maxWidth="1024px" id="newsStart">

        {crumbs.length > 1 &&
          <Box mb="100px">
            <Breadcrumb crumbs={crumbsUnslugged} crumbSeparator=" » " />
          </Box>
        }

        <Heading as="h4" fontWeight="normal" lineHeight="normal" mb="20px">
          {title}
        </Heading>

        {!newExcerpt.hidePublishDate &&
          <Heading as="h6" fontWeight="normal" mb={50}>
            {date}
          </Heading>
        }

        <div className="blocksWrapper">
          { isJsonString(data.page.blocksJSON) &&
            <Blocks blocks={JSON.parse(data.page.blocksJSON)} />
          }
        </div>

        <SimpleGrid mt="100px" mb="100px" columns="2" spacing="30px">

          <Box>
            {!!previousPage && (
              <Link to={`${normalizePath(previousPage.uri)}#newsStart`}>
                Previous: {previousPage.title}
              </Link>
            )}
          </Box>
          <Box>
            {!!nextPage && (
              <Link to={`${normalizePath(nextPage.uri)}#newsStart`}>Next: {nextPage.title}</Link>
            )}
          </Box>
          
        </SimpleGrid>

      </Box>

    </Layout>
  )
}

export default BlogPost
